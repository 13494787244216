<template lang="html">
  <div class="navTop">
    <div class="left">
      <div class="title">
        牧予生活
      </div>
      <div class="box">
        <div class="list" @click="scrollTo(0)" :class="{act:act===0}">
          首页
        </div>
        <div class="list" @click="scrollTo(1)" :class="{act:act===1}">
          产品
        </div>
        <div class="list" @click="scrollTo(2)" :class="{act:act===2}">
          解决方案
        </div>
        <div class="list" @click="scrollTo(3)" :class="{act:act===3}">
          联系我们
        </div>
      </div>
    </div>
    <div class="right" @mouseenter="popShow = true" @mouseleave="popShow = false" v-if="userInfo && show"
         @click="$router.push('/personalCenter')">
      <div>
        <img :src="userInfo.user.headerImg ? userInfo.user.headerImg : require('../assets/img/profile.png')" alt="">
        <span>{{ userInfo.user.nickName }}</span>
      </div>
      <transition name="pop">
        <div class="pop" @mouseenter="popShow = true" @mouseleave="popShow = false" v-if="popShow">
          <div class="items" v-if="userInfo.user.userType == 20"
               @click.stop="$router.push({path: '/modifyInformation'})">企业账号中心
          </div>
          <div class="items" v-if="userInfo.user.userType == 10"
               @click.stop="$router.push({path: '/modifyInformation'})">个人账号中心
          </div>
          <div class="items" @click.stop="loginOut">
            <img src="../assets/img/out.png"/>
            <span>退出登录</span>
          </div>

        </div>
      </transition>

    </div>
    <div v-if="!userInfo" class="btns">
      <div @click="$router.push({path:'/login',query:{type:1}})">登录</div>
      <div @click="$router.push({path:'/login',query:{type:2}})">注册</div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'navTop',
  props: {
    show: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  data() {
    return {
      userInfo: window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null,
      popShow: false,
      act: -1,
    };
  },
  watch: {
    '$route':{
      handler(newV){
        if(newV.path == '/home'){
          this.act = 0;
        }else {
          this.act = -9
        }
      },
      immediate:true
    }
  },
  mounted() {
    // 监听滚动事件
    if(this.$route.path=='/home'){
      window.addEventListener('scroll', this.onScroll, false)
    }

  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    loginOut() {
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('userInfo');
      // window.location.href = '/#/';
      this.$router.replace('login')
    },
    goPage(url) {
      this.$router.push({path: url})
    },
    // 滚动监听器
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll('.HomeContent .pageList')
      // 所有锚点元素的 offsetTop
      const offsetTopArr = []
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop)
      })
      // 获取当前文档流的 scrollTop
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 定义当前点亮的导航下标
      let navIndex = 0
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (scrollTop + 80 >= offsetTopArr[n]) {
          navIndex = n
        }
      }
      this.act = navIndex
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      this.$router.push({path:'/home',query:index})
      setTimeout(()=>{
        this.$nextTick(()=>{
          if(this.$route.path!="/home"){
            return false
          }
          this.act = index;
          // 获取目标的 offsetTop
          // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
          const targetOffsetTop = document.querySelector(`.HomeContent .pageList:nth-child(${index + 1})`).offsetTop
          // 获取当前 offsetTop
          let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉，步子迈大了会扯到蛋
          const STEP = 50
          // 判断是往下滑还是往上滑
          if (scrollTop > targetOffsetTop) {
            // 往上滑
            smoothUp()
          } else {
            // 往下滑
            smoothDown()
          }

          // 定义往下滑函数
          function smoothDown() {
            // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
            if (scrollTop < targetOffsetTop) {
              // 如果和目标相差距离大于等于 STEP 就跳 STEP
              // 否则直接跳到目标点，目标是为了防止跳过了。
              if (targetOffsetTop - scrollTop >= STEP) {
                scrollTop += STEP
              } else {
                scrollTop = targetOffsetTop
              }
              document.body.scrollTop = scrollTop - 80
              document.documentElement.scrollTop = scrollTop - 80
              // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
              requestAnimationFrame(smoothDown)
            }
          }

          // 定义往上滑函数
          function smoothUp() {
            if (scrollTop > targetOffsetTop) {
              if (scrollTop - targetOffsetTop >= STEP) {
                scrollTop -= STEP
              } else {
                scrollTop = targetOffsetTop
              }
              document.body.scrollTop = scrollTop - 80
              document.documentElement.scrollTop = scrollTop - 80
              requestAnimationFrame(smoothUp)
            }
          }
        })
      },40)


    },
  },
};
</script>

<style lang="scss" scoped>
.navTop {
  width: 100%;
  min-width: 1400px;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  .left {
    display: flex;
    align-items: center;

    .title {
      margin-left: 50px;
      font-size: 20px;
      font-family: MicrosoftYaHei-Bold;
      font-weight: bold;
      color: #333333;
    }

    .box {
      display: flex;
      align-items: center;
      margin-left: 55px;

      .list {
        margin-right: 44px;
        color: #000000;
        cursor: pointer;
        height: 60px;
        line-height: 60px;
        padding: 0 15px;
        text-align: center;
        box-sizing: border-box;

        &.act {
          position: relative;
          color: #3D7EFF;

          &:before {
            content: ' ';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: #3D7EFF;
          }
        }

        .act {
          &:before {
            content: ' ';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: #3D7EFF;
          }
        }

        &:hover {
          position: relative;
          color: #3D7EFF;

          //&:before {
          //  content: ' ';
          //  position: absolute;
          //  left: 0;
          //  bottom: 0;
          //  width: 100%;
          //  height: 2px;
          //  background: #3D7EFF;
          //}
        }
      }
    }
  }

  .right {
    margin-right: 50px;
    color: #666666;
    cursor: pointer;
    position: relative;

    img {
      width: 30px;
      height: 30px;
      margin-right: 15px;
    }

    .pop {
      width: 250px;
      // height: 30px;
      background: #FFFFFF;
      text-align: center;
      line-height: 30px;
      position: absolute;
      bottom: -110px;
      right: 0;
      box-shadow: 0 0 5px rgb(223, 221, 221);
      // color: #FFFFFF;
      .items {
        border-bottom: 1px solid #f4f4f4;
        height: 50px;
        line-height: 50px;
        //  text-align: center;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 12px;
          height: 12px;
          vertical-align: bottom;
          margin: 0;
          margin-right: 5px;
        }
      }

      &::after {
        content: "";
        display: block;
        // width: 20px;
        // height: 20px;
        // border: 20px solid blue;
        box-sizing: border-box;
        border-left: 8px solid transparent;
        border-top: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #FFFFFF;
        position: absolute;
        top: -15px;
        right: 0;
        // transform: translate(-50%);
        z-index: 9999;
      }
    }

    .pop-enter, .pop-leave-to {
      opacity: 0;
    }

    .pop-enter-active, .pop-leave-active {
      transition: all .5s;
    }

    .pop-enter-to, .pop-leave {
      opacity: 1;
    }
  }
  .btns{
    margin-right: 50px;
    display: flex;
    align-items: center;
    div{
      width: 62px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      text-align: center;
      cursor: pointer;

      &:first-child{

      }
      &:last-child{
        background: #3D7EFF;
        color: #FFFFFF;
      }
    }
  }
}
</style>
